@import "../../assets//styles/ui-kit/_variables.scss";


.multiple {
  .form-control {
    border-radius: 0 !important;
  }
  .multiple-last {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

.form-label {
  font-size: 14px !important;
  font-family: "PlusJakartaSans-Bold";
  margin-bottom: 12px;
}

.form-control {
  background: #f5f6fa 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 14px;
  letter-spacing: 0px;
  color: #222d17;
  padding-left: 15px 20px;
}

input.form-control {
  height: 48px !important;
}

.end-icon {
  right: 10px;
}

.DateRangePicker {
  margin-bottom: 10px;
  padding: 0;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1 !important;
  background: white;
  border: solid 1px #0000001a;
  padding: 8px 3px;
  border-radius: 5px;
  left: 0px;
}

.DateRangePicker__CalendarSelection {
  background-color: #21bad5 !important;
  border: 1px solid #00abbd !important;
  bottom: 5px;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 5px;
}

.input-wrapper {
  position: relative;
}

.right-icon-input {
  position: absolute;
  right: 15px;
  top: 8px;
  color: inherit;
}

.left-icon-input {
  position: absolute;
  left: 15px;
  top: 15px;
  color: inherit;
}

.left-icon-input + .form-control {
  padding-left: 50px;
}

.form-group .input-wrapper .form-control::placeholder {
  // font-size: 14px !important;
  color: #808080 !important;
}

input:disabled,
textarea:disabled {
  background-color: #e9ecef !important;
  color: #7c8798 !important;
}

.select__control--is-disabled {
  background-color: #e9ecef !important;
  color: #7c8798 !important;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-right: 13px;
  margin-left: 0;
}

.form-check-label {
  font-size: 14px !important;
}

label.file-label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}

.upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.float-left-force {
  margin-right: auto !important;
}

.ml-15 {
  margin-left: 15px;
}
/* The container-checkbox */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.css-1rhbuit-multiValue {
  background-color: #63AE5C !important;
  color: white !important;
  border-radius: 20px !important;
  padding-left: 10px !important;
}

.css-12jo7m5 {
  color: white !important;
}

.input-group-prepend {
  width: 50%;
  span {
    width: 100%;
  }
}

.react-tel-input .country-list {
  .search {
    padding: 10px !important;
  }

  .search-box {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .country {
    padding: 5px 9px 5px 46px !important;
  }

  .flag {
    margin-top: 0px !important;
    top: 5px !important;
  }
} 